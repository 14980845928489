import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./containers/Home";

import Photography from "./containers/Photography";
import YouTube from "./containers/YouTube";
import Products from "./containers/Products";
import HireMe from "./containers/HireMe";
import NotFound from "./containers/NotFound";

export default function Routes({appProps}) {
    return (
        <Switch>
            <Route path="/" exact component={Home} appProps={appProps}/>

            <Route path="/photography" exact component={Photography} appProps={appProps}/>
            <Route path="/youtube" exact component={YouTube} appProps={appProps}/>
            <Route path="/products" exact component={Products} appProps={appProps}/>
            <Route path="/hire-me" exact component={HireMe} appProps={appProps}/>

            { /* Finally, catch all unmatched routes */}
            <Route component={NotFound}/>
        </Switch>
    );
}