import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import SocialBar from "./SocialBar";

import "./Header.css";

class Header extends Component {
    render() {
        return (
            <div className="Header">
                <div class="home"><a href="/">Suraj</a></div>
                <div class="nav-links">
                    <NavLink to="/photography">Photography</NavLink>
                    <NavLink to="/youtube">YouTube</NavLink>
                    <NavLink to="/products">Products</NavLink>
                </div>
                <SocialBar/>
            </div>
        );
    }
}

export default Header;
