import React, {Component} from "react";
import "./InfoLink.css";

export default class InfoLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        };
        this.toggleHover = this.toggleHover.bind(this);
    }

    toggleHover() {
        this.setState({
            isHovered: !this.state.isHovered
        });
    }

    render() {
        var styleBorder = {}
        if (this.state.isHovered) {
            styleBorder['border'] = '1px solid ' + this.props.color;
        }
        return (
            <div className="InfoLink"
                 onMouseEnter={this.toggleHover}
                 onMouseLeave={this.toggleHover}
                 style={styleBorder}
            >
                <a href={this.props.path}>
                    <div class="button-description"
                         style={{'background-color': this.props.color}}
                    >
                        {this.props.buttonDescription}
                    </div>
                    <div class="description"
                         style={{'color': this.props.color}}
                    >
                        {this.props.description}
                        <span class="glyphicon glyphicon-chevron-right"></span>
                    </div>
                </a>
            </div>
        );
    }
}
