import React from "react";
import { withRouter } from "react-router-dom";
import Header from "./containers/Header";
import Footer from "./containers/Footer";
import Routes from "./Routes";

import "./App.css";

function App(props) {
    return (
        <div className="App container">
            <Header>
            </Header>
            <Routes/>
            <Footer>
            </Footer>
        </div>
    );
}

export default withRouter(App);
