import React, {Component} from "react";

import "./Footer.css";

class Footer extends Component {
    render() {
        return (
            <div className="Footer">
                <div class="contact">
                    <h1>Contact</h1>
                    <p> I'm always looking for new projects and opportunities. <br/>
                        <a href="/hire-me">Hire me</a> if you'd like to work together. </p>
                </div>
                <div class="copyright">
                    &copy; 2020 Suraj Rajan. All Rights Reserved.
                </div>
                <div class="description">
                    This website was designed and built entirely by me with React and hosted on AWS.
                </div>
            </div>
        );
    }
}

export default Footer;
