import React, {Component} from "react";

import "./Photography.css";
import Gallery from "react-photo-gallery";

import barcelona from '../img/portfolio/travel/barcelona.jpg';
import bangkok from '../img/portfolio/travel/bangkok.jpg';
import ReactGA from "react-ga";
import {withRouter} from "react-router-dom";

function Photography() {

    ReactGA.pageview(window.location.pathname + window.location.search);
    let city = [
        {
            src: barcelona,
            width: 4,
            height: 5
        },
        {
            src: bangkok,
            width: 4,
            height: 5
        }
    ];

    return (
        <div className="Photography">
            <h1>city</h1>
            <Gallery
                direction={'column'}
                photos={city}
            />
            <div className="MyGear">
                <h1>My Gear</h1>
                <h2>A7III &#xb7; Leica Q-P &#xb7; Mavic 2 Pro &#xb7; iPhone XS Max</h2>
                <h3>28-75mm Tamron F2.8</h3>
            </div>
        </div>
    );

}

export default withRouter(Photography);
