import React, {Component} from "react";
import {Image} from "react-bootstrap";
import "./Products.css";

import flightboard from '../img/products/flightboard.png';
import habitstreaks from '../img/products/habitstreaks.png';
import ReactGA from "react-ga";
import {withRouter} from "react-router-dom";

function Products() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className="Products">
            <h2>here are some products i've single-handedly designed, implemented, and launched myself </h2>
            <div className="ProductList">
                <div className="Category">
                    Travel
                </div>
                <a href="https://theflightboard.com" target="_blank">
                    <div className="Product">
                        <h1>
                            theflightboard
                        </h1>
                        <h2>
                            autmomatically searched, premium flight deals
                        </h2>
                        <Image
                            src={flightboard}
                            width="800"
                            rounded
                            responsive
                        >
                        </Image>
                    </div>
                </a>
                <div className="Category">
                    Productivity
                </div>
                <a href="https://habitstreaks.com" target="_blank">
                    <div className="Product">
                        <h1>
                            HabitStreaks
                        </h1>
                        <h2>
                            a simple, minimal, habit tracking app
                        </h2>
                        <Image
                            src={habitstreaks}
                            width="800"
                            rounded
                            responsive
                        >
                        </Image>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default withRouter(Products);
