import React, {Component} from "react";
import {Image} from "react-bootstrap";
import InfoLink from "../containers/buttons/InfoLink"
import "./Home.css";
import me from '../img/me.jpg';
import ReactGA from "react-ga";
import {withRouter} from "react-router-dom";

function Home() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className="Home">
            <h1>I'm Suraj - a software engineer, photographer, and entrepreneur based in Seattle. </h1>
            <InfoLink
                path="/hire-me"
                buttonDescription="contact me"
                description="if you'd like to work together"
                color="#ff4d4d"
            />
            <a href="./photography">
                <Image
                    src={me}
                    rounded
                    responsive
                >
                </Image>
            </a>
        </div>
    );
}

export default withRouter(Home);
