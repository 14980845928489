import React from "react";
import YouTube from 'react-youtube';

import "./YouTube.css";
import {useWindowDimensions} from "../libs/hooksLib";
import ReactGA from "react-ga";
import {withRouter} from "react-router-dom";

function YouTubeContainer() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    let {width} = useWindowDimensions();
    if (width > 768) width = 768;
    const opts = {
        width: width - 90,
    };
    return (
        <div className="YouTubeContainer">
            <h1>Travel</h1>
            <div className="Videos">
                <YouTube videoId="69o7FZG0Y7U" opts={opts}/>
                <YouTube videoId="OMsaJibi2v8" opts={opts}/>
                <YouTube videoId="7brSnpIV_NE" opts={opts}/>
                <YouTube videoId="WionWZb2Mxo" opts={opts}/>
            </div>
        </div>
    );
}

export default withRouter(YouTubeContainer);
