import React, {Component} from "react";

import InfoLink from "../containers/buttons/InfoLink"
import "./HireMe.css";
import ReactGA from "react-ga";
import {withRouter} from "react-router-dom";

function HireMe() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
            <div className="HireMe">
                <div className="Description">
                    <h1>About</h1>
                    <p>I'm Suraj - a software engineer, photographer, and entrepreneur based in Seattle.</p>
                    <p>My day job is being a senior software engineer / tech lead at Amazon - for more
                        information about that, see my <a href="https://www.linkedin.com/in/suraj-rajan/">LinkedIn</a>.
                    </p>
                </div>
                <div className="HireMe">
                    <InfoLink
                        path="mailto:hi@surajrajan.com"
                        buttonDescription="email"
                        description="get in touch with me"
                        color="#ff4d4d"
                    >
                    </InfoLink>
                </div>
            </div>
        );
}

export default withRouter(HireMe);
