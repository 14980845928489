import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ReactGA from 'react-ga';

import "./index.css";

// Initialize Google Analytics Tracker
ReactGA.initialize("UA-175569310-1");

ReactDOM.render(
    <Router>
        <App/>
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
