import React, {Component} from "react";
import {SocialIcon} from 'react-social-icons';

import "./SocialBar.css";

export default class SocialBar extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.state['youtube'] = false;
        this.state['instagram'] = false;
        this.state['linkedin'] = false;

        this.handleHover = this.handleHover.bind(this);
        this.getIcon = this.getIcon.bind(this);
    }

    handleHover(social) {
        this.setState({
            [social]: !this.state[social]
        });
    }

    getIcon(link, hoveredColor, service) {
        var social;
        if (!this.state[service]) {
            social = <SocialIcon target="_blank" url={link} bgColor="white" fgColor="#AAB2BD"/>
        } else {
            social = <SocialIcon target="_blank" url={link} bgColor="white" fgColor={hoveredColor}/>
        }
        return social;
    }

    render() {
        var youtube = this.getIcon("https://www.youtube.com/channel/UCY3gezWkK8pEOw64BZ0MMtQ", "#ff0000", "youtube");
        var instagram = this.getIcon("https://www.instagram.com/surajrajan_", "#fcd574", "instagram");
        var linkedin = this.getIcon("https://www.linkedin.com/in/suraj-rajan", "#0077b5", "linkedin");

        return (
            <div class="SocialBar">
                <div className="SocialIcon" onMouseEnter={() => this.handleHover('youtube')}
                     onMouseLeave={() => this.handleHover('youtube')}>
                    {youtube}
                </div>
                <div className="SocialIcon" onMouseEnter={() => this.handleHover('instagram')}
                     onMouseLeave={() => this.handleHover('instagram')}>
                    {instagram}
                </div>
                <div className="SocialIcon" onMouseEnter={() => this.handleHover('linkedin')}
                     onMouseLeave={() => this.handleHover('linkedin')}>
                    {linkedin}
                </div>
            </div>
        );
    }
}
